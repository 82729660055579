// Reset active steps
export function resetSteps() {
    $('.js--cake-constructor-progress-step').each(function() {
        $(this).removeClass('active-step');
    });
}

// Set specific step as active
export function setActiveStep(step) {
    resetSteps();
    $('.js--cake-constructor-progress-step').eq(step - 1).addClass('active-step');
}
$('.js--upload-custom-cake-files').on('click', function() {

    var filewrapper = document.querySelector('.js--attached-filepath');
    var fileupload  = document.querySelector('.js--cake-filefield');

    console.log(filewrapper);
    console.log(fileupload);

    fileupload.click();
    fileupload.onchange = function () {
        console.log('onchange');

        while( filewrapper.hasChildNodes() ){
            filewrapper.removeChild(filewrapper.lastChild);
        }

        var files = fileupload.files;
        console.log(files);
        [].forEach.call(files, function (file) {
            filewrapper.insertAdjacentHTML( 'beforeend', '<div class="attached-file js--attached-file"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_635_1507)"><path d="M14.6783 7.94049L8.57946 14.0393C7.40743 15.2113 5.50885 15.2113 4.33682 14.0393C3.16479 12.8673 3.16479 10.9687 4.33682 9.79665L10.9659 3.16752C11.3176 2.81589 11.7945 2.61835 12.2918 2.61835C12.7891 2.61835 13.266 2.81589 13.6176 3.16752C13.9692 3.51915 14.1668 3.99607 14.1668 4.49335C14.1668 4.99063 13.9692 5.46754 13.6176 5.81917L8.04913 11.3876C7.75745 11.6793 7.28015 11.6793 6.98847 11.3876C6.69679 11.096 6.69679 10.6187 6.98847 10.327L12.0266 5.28884L11.2311 4.49335L6.19298 9.53148C5.84135 9.88311 5.6438 10.36 5.6438 10.8573C5.6438 11.3546 5.84135 11.8315 6.19298 12.1831C6.54461 12.5348 7.02152 12.7323 7.5188 12.7323C8.01608 12.7323 8.493 12.5348 8.84463 12.1831L14.4131 6.61467C15.5851 5.44264 15.5851 3.54406 14.4131 2.37203C13.2411 1.2 11.3425 1.2 10.1705 2.37203L3.54133 9.00115C1.92912 10.6134 1.92912 13.2226 3.54133 14.8348C5.15353 16.447 7.76275 16.447 9.37496 14.8348L15.4738 8.73599L14.6783 7.94049Z" fill="currentColor"/></g><defs><clipPath id="clip0_635_1507"><rect width="18" height="18" fill="white"/></clipPath></defs></svg>' + file.name);
        });

    };
    

});
import { cakeConstructorResetRenderFloors } from './reset.js';
import { getCakeComposition, getCakeProduct } from './ajax.js';
import { setActiveStep } from './steps.js';

// STEP 2: Select the cake type
$('.js--cake-type-selector').on('click', function(e) {
    
    // Reset
    cakeConstructorResetRenderFloors();
    
    // Variables
    var cake_type = $(this).data('cake-type');
    var max_floor = $(this).data('max-floors');

    // Set values
    $('#ccCakeType').val(cake_type);
    $('#ccMaxFloor').val(max_floor);

    $('.js--cake-constructor-step-1').hide(); // LOCK FOR DEVELOPMENT PURPOSES ONLY!
    $('.js--cake-constructor-cake').hide();
    $('.js--cake-constructor-cake-' + cake_type).show();
    $('.js--floor-render-' + cake_type).eq(0).show();
    $('.js--floor-taste-' + cake_type).eq(0).show();

    // Get composition
    getCakeComposition();
    getCakeProduct();
    setActiveStep(2);

});
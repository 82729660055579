// Add floor
export function addFloor() {

    var cake_type = $('#ccCakeType').val();
    var max_product_floors = $('#ccProductFloors').val();
    var floors_count = $('#ccFloorsCount').val();

    if (cake_type == 'custom') return;

    if (floors_count < max_product_floors) {
        floors_count++;

        $('#ccFloorsCount').val(floors_count);
        $('.js--floor-render-' + cake_type).eq(floors_count - 1).show();
        $('.js--floor-taste-' + cake_type).eq(floors_count - 1).show();

        console.info('add_floor', floors_count, max_product_floors);

    } else {
        console.warn('max_floor_reached', floors_count, max_product_floors);
    }

}

// Remove floor
export function removeFloor() {

    var cake_type = $('#ccCakeType').val();
    var floors_count = $('#ccFloorsCount').val();

    if (cake_type == 'custom') return;
    
    if (floors_count > 1) {
        floors_count--;

        $('#ccFloorsCount').val(floors_count);
        $('.js--floor-render-' + cake_type).eq(floors_count).hide();
        $('.js--floor-taste-' + cake_type).eq(floors_count).hide();

        // Rounded cake
        $('.js--floor-render-' + cake_type).eq(floors_count).find('svg > .top-side').css('fill', '').css('opacity', '');
        $('.js--floor-render-' + cake_type).eq(floors_count).find('svg > .left-side').css('fill', '').css('opacity', '');
        $('.js--floor-render-' + cake_type).eq(floors_count).find('svg > .right-side').css('fill', '').css('opacity', '');

        // Square cake 
        $('.js--floor-render-' + cake_type).eq(floors_count).find('svg > .top-s-side').css('fill', '').css('opacity', '');
        $('.js--floor-render-' + cake_type).eq(floors_count).find('svg > .left-s-side').css('fill', '').css('opacity', '');
        $('.js--floor-render-' + cake_type).eq(floors_count).find('svg > .right-s-side').css('fill', '').css('opacity', '');
        
        // Reset visible tastes and setted options
        $('.js--floor-taste-' + cake_type).eq(floors_count).each(function( index, item ) {
            $(this).find('.js--show-all-floor-tastes').removeClass('d-none');
            $(item).find('.btn').each(function( i, btn ) {
                $(btn).find('input').prop('checked', false);
                $(btn).removeClass('active');
                if(i > 6) {
                    $(btn).addClass('d-none');
                    $(btn).addClass('d-none');
                }
            });
        });
        
        console.info('remove_floor', floors_count, 1);
    } else {
        console.warn('min_floor_reached', floors_count, 1);
    }

}
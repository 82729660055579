import { createCakeWeightSlider } from './weight.js';

// Get by AJAX product composition
export function getCakeComposition() {
    $.ajax({
      type: 'GET',
      url: '/ajax/product/get-composition/' + $('#ccProductId').val() + '/',
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      success: function (response) {
        if (response.product.product_composition != null) {
            $('.js--cake-composition-content-standard').hide();
            $('.js--cake-composition-content-ajax').html($(response.product.product_composition.description).text()).show();
        } else {
            $('.js--cake-composition-content-standard').show();
            $('.js--cake-composition-content-ajax').html(null).hide();
        }
      },
      error: function( response ) {
        console.error('Error: ' + response);
      }
    });
}

// Get by AJAX product
export function getCakeProduct() {
    var product;

    $.ajax({
      async: false,
      type:  'GET',
      url:   '/ajax/product/get-product/' + $('#ccProductId').val() + '/',
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      success: function (response) {
        product = response.product;
        createCakeWeightSlider(response.product.min_qty, response.product.max_qty);
      },
      error: function( response ) {
        console.error('Error: ' + response);
      }
    });

    return product;

}

// Get cake base price
export function getCakeBasePrice() {
    var price;

    $.ajax({
      async: false,
      type:  'GET',
      url:   '/cake-constructor/baseprice/',
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      success: function (response) {
        price = response.price;
      },
      error: function( response ) {
        console.error('Error: ' + response);
      }
    });

    return price;

}

// Get cake cherries settings
export function getCherriesSettings() {
    var settings;

    $.ajax({
      async: false,
      type:  'GET',
      url:   '/cake-constructor/cherries-settings/',
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      success: function (response) {
        settings = JSON.parse(response.settings.value);
      },
      error: function( response ) {
        console.error('Error: ' + response);
      }
    });

    return settings;

}

// Get product attribute
export function getProductAttribute(attribute) {
    var product = getCakeProduct();
    return product[attribute];
}
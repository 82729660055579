// Highlight on floor hover
$('.js--floor-render > svg').on('mouseover', function(e) {
    var cake_floor = $(this).parent().data('cake-floor');
    $('.js--floor-taste').each(function () {
        $(this).removeClass('floor-active');
    });
    $('.js--floor-taste[data-cake-floor="' + cake_floor + '"]').addClass('floor-active');
});

// Clear highlight on mouse leave
$('.js--floor-render > svg').on('mouseleave', function(e) {
    $('.js--floor-taste').each(function () {
        $(this).removeClass('floor-active');
    });
});
const { each } = require("jquery");
const nouislider = require("nouislider");

console.log('CC init');

import { cakeConstructorReset, cakeConstructorResetRenderFloors } from './reset.js';
import { getCakeComposition, getCakeProduct } from './ajax.js';

require('./floors_highlight.js');
require('./floors_actions.js')
require('./floors_tastes.js');
require('./custom.js');
require('./step_1.js');
require('./step_2.js');

// Reset constructor to initial state
export function cakeConstructorReset() {
    
    // Reset steps
    $('.js--cake-constructor-step-1').show();
    $('.js--cake-constructor-step-2').hide();
    
    // Reset variables
    $('#ccProductId').val(null);
    $('#ccProductPrice').val(null);
    $('#ccProductQty').val(null);
    $('#ccProductWeight').val(null);
    // $('#ccProductFloors').val(null);

    $('#ccCakeType').val(null);
    $('#ccMaxFloor').val(null);
    $('#ccFloorsCount').val(null);
    $('#ccCakeWeight').val(null);

    // Reset compositions
    $('.js--cake-composition-content-ajax').html(null).hide();
    $('.js--cake-composition-content-standard').show();
    $('.js--cake-composition-content-floor-1-ajax').html(null).show();
    $('.js--cake-composition-content-floor-2-ajax').html(null).show();
    $('.js--cake-composition-content-floor-3-ajax').html(null).show();
    $('.js--cake-composition-content-floor-4-ajax').html(null).show();
    $('.js--cake-composition-content-floor-5-ajax').html(null).show();

    // Reset floors
    cakeConstructorResetRenderFloors();
}

// Reset renderer
export function cakeConstructorResetRenderFloors() {
    
    $('.js--floor-render').hide();
    $('.js--floor-taste').hide();
    $('#ccFloorsCount').val(1);
    
    // Hide buttons for one floor cakes
    if ($('#ccProductFloors').val() == 1) {
        $('.js--add-floor').hide();
        $('.js--remove-floor').hide();
        $('.cake-constructor-header').css('display', 'block');

    } else {
        $('.js--add-floor').show();
        $('.js--remove-floor').show();
        $('.cake-constructor-header').css('display', 'flex');
    }

    // Rounded cake
    $('.js--floor-render > svg > .top-side').css('fill', '').css('opacity', '');
    $('.js--floor-render > svg > .left-side').css('fill', '').css('opacity', '');
    $('.js--floor-render > svg > .right-side').css('fill', '').css('opacity', '');

    // Square cake 
    $('.js--floor-render > svg > .top-s-side').css('fill', '').css('opacity', '');
    $('.js--floor-render > svg > .left-s-side').css('fill', '').css('opacity', '');
    $('.js--floor-render > svg > .right-s-side').css('fill', '').css('opacity', '');

    // Reset visible tastes and setted options
    $('.js--floor-taste').each(function( index, item ) {
        $(this).find('.js--show-all-floor-tastes').removeClass('d-none');
        $(item).find('.btn').each(function( i, btn ) {
            $(btn).find('input').prop('checked', false);
            $(btn).removeClass('active');
            if(i > 6) {
                $(btn).addClass('d-none');
            }
        });
    });

}
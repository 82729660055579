// Import and load node modules
window.$ = window.jQuery = require('jquery');
window.JqueryValidation = require('jquery-validation');
window.Bootstrap = require('bootstrap');
window.owlCarousel = require('owl.carousel2');
window.Popper = require('popper.js');
window.tether = require('tether');
window.flatpickr = require("flatpickr");
import { Lithuanian } from "flatpickr/dist/l10n/lt.js"
window.confirmDatePlugin = require("flatpickr/dist/plugins/confirmDate/confirmDate");
window.noUiSlider = require('nouislider');
window.glightbox = require('glightbox');
window.select2 = require('select2');
window.toastr = require('toastr');
window.CC = require('cookieconsent');

// Icons
const icon_loading = '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.92 17.84C9.98039 17.84 10.84 16.9804 10.84 15.92C10.84 14.8596 9.98039 14 8.92 14C7.85961 14 7 14.8596 7 15.92C7 16.9804 7.85961 17.84 8.92 17.84Z" fill="currentColor"/><path d="M16.5997 17.84C17.6601 17.84 18.5197 16.9804 18.5197 15.92C18.5197 14.8596 17.6601 14 16.5997 14C15.5393 14 14.6797 14.8596 14.6797 15.92C14.6797 16.9804 15.5393 17.84 16.5997 17.84Z" fill="currentColor"/><path d="M24.2794 17.84C25.3398 17.84 26.1994 16.9804 26.1994 15.92C26.1994 14.8596 25.3398 14 24.2794 14C23.219 14 22.3594 14.8596 22.3594 15.92C22.3594 16.9804 23.219 17.84 24.2794 17.84Z" fill="currentColor"/></svg>';
const icon_chevron = '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.43 6L11 7.393L18.58 15L19.4297 16L18.58 17L11 24.573L12.43 26L22.43 16L12.43 6Z" fill="currentColor"/></svg>';
const icon_warning = '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 21C15.7033 21 15.4133 21.088 15.1666 21.2528C14.92 21.4176 14.7277 21.6519 14.6142 21.926C14.5006 22.2001 14.4709 22.5017 14.5288 22.7926C14.5867 23.0836 14.7296 23.3509 14.9393 23.5607C15.1491 23.7704 15.4164 23.9133 15.7074 23.9712C15.9983 24.0291 16.2999 23.9993 16.574 23.8858C16.8481 23.7723 17.0824 23.58 17.2472 23.3334C17.412 23.0867 17.5 22.7967 17.5 22.5C17.5 22.1022 17.342 21.7206 17.0607 21.4393C16.7794 21.158 16.3978 21 16 21ZM15 8H17V18H15V8Z" fill="currentColor"/><path d="M23.0003 29H9.0003C8.82549 29.0001 8.65373 28.9543 8.50213 28.8673C8.35053 28.7802 8.2244 28.655 8.1363 28.504L1.1363 16.504C1.04704 16.351 1 16.1771 1 16C1 15.8229 1.04704 15.649 1.1363 15.496L8.1363 3.496C8.2244 3.34502 8.35053 3.21978 8.50213 3.13275C8.65373 3.04572 8.82549 2.99995 9.0003 3H23.0003C23.1751 2.99995 23.3469 3.04572 23.4985 3.13275C23.6501 3.21978 23.7762 3.34502 23.8643 3.496L30.8643 15.496C30.9536 15.649 31.0006 15.8229 31.0006 16C31.0006 16.1771 30.9536 16.351 30.8643 16.504L23.8643 28.504C23.7762 28.655 23.6501 28.7802 23.4985 28.8673C23.3469 28.9543 23.1751 29.0001 23.0003 29ZM9.5743 27H22.4263L28.8423 16L22.4263 5H9.5743L3.1583 16L9.5743 27Z" fill="currentColor"/></svg>';
const icon_cart    = '<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 30.6328C11.1046 30.6328 12 29.7374 12 28.6328C12 27.5282 11.1046 26.6328 10 26.6328C8.89543 26.6328 8 27.5282 8 28.6328C8 29.7374 8.89543 30.6328 10 30.6328Z" fill="currentColor"></path><path d="M24 30.6328C25.1046 30.6328 26 29.7374 26 28.6328C26 27.5282 25.1046 26.6328 24 26.6328C22.8954 26.6328 22 27.5282 22 28.6328C22 29.7374 22.8954 30.6328 24 30.6328Z" fill="currentColor"></path><path d="M28 7.63302H5.82L5 3.43302C4.95325 3.20374 4.82758 2.99812 4.64486 2.85194C4.46214 2.70577 4.23395 2.6283 4 2.63302H0V4.63302H3.18L7 23.833C7.04675 24.0623 7.17242 24.2679 7.35514 24.4141C7.53786 24.5603 7.76605 24.6377 8 24.633H26V22.633H8.82L8 18.633H26C26.2312 18.6387 26.4571 18.564 26.6395 18.4218C26.8218 18.2796 26.9492 18.0786 27 17.853L29 8.85302C29.0335 8.70464 29.0327 8.55058 28.9977 8.40255C28.9627 8.25452 28.8944 8.11643 28.798 7.99879C28.7015 7.88114 28.5795 7.78705 28.4413 7.72367C28.303 7.66028 28.1521 7.62928 28 7.63302ZM25.2 16.633H7.62L6.22 9.63302H26.75L25.2 16.633Z" fill="currentColor"></path></svg>';
const icon_success = '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 21.414L9 16.413L10.413 15L14 18.586L21.585 11L23 12.415L14 21.414Z" fill="currentColor"/><path d="M16 2C13.2311 2 10.5243 2.82109 8.22202 4.35943C5.91973 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM16 28C13.6266 28 11.3066 27.2962 9.33316 25.9776C7.35977 24.6591 5.8217 22.7849 4.91345 20.5922C4.0052 18.3995 3.76756 15.9867 4.23058 13.6589C4.69361 11.3311 5.83649 9.19295 7.51472 7.51472C9.19296 5.83649 11.3312 4.6936 13.6589 4.23058C15.9867 3.76755 18.3995 4.00519 20.5922 4.91345C22.7849 5.8217 24.6591 7.35977 25.9776 9.33316C27.2962 11.3065 28 13.6266 28 16C28 19.1826 26.7357 22.2348 24.4853 24.4853C22.2348 26.7357 19.1826 28 16 28Z" fill="currentColor"/></svg>';

const { Dropzone } = require('dropzone');

// Cake constructor import
require('./cakeConstructor/cake.js');

// Cookies
window.cookieconsent.initialise({
  palette: {
    popup: {
      background: '#511826',
      text: '#ffffff'
    },
    button: {
      background: '#ffffff',
      text: '#511826'
    }
  },
  type: 'opt-out',
  content: {
    message: 'Šioje svetainėje naudojame būtinus slapukus (Angl. “​Cookies”), kurie užtikrina svetainės funkcionavimą. Jeigu sutiksite, naudosime  ir kitus našumo ir taikymo slapukus, kurie mums padeda analixuoti srautą bei tobulinti svetainę. Savo sutikimą visada galėsite pakeisti ar atšaukti.',
    allow: 'Sutinku',
    deny: 'Nesutinku',
    policy: 'Privatumo politika',
    link: 'Daugiau informacijos apie slapukus pateikiame čia.',
    href: '/privatumo-politika'
  },
  onInitialise: function (status) {
    var type = this.options.type;
    var didConsent = this.hasConsented();
    if (type == 'opt-in' && didConsent) {
      // enable cookies
    }
    if (type == 'opt-out' && !didConsent) {
      // disable cookies
      var cookies = document.cookie.split(";");
      for(var i=0; i < cookies.length; i++) {
        var equals = cookies[i].indexOf("=");
        var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
  },
  onStatusChange: function(status, chosenBefore) {
    var type = this.options.type;
    var didConsent = this.hasConsented();
    if (type == 'opt-in' && didConsent) {
      // enable cookies
    }
    if (type == 'opt-out' && !didConsent) {
      // disable cookies
      var cookies = document.cookie.split(";");
      for(var i=0; i < cookies.length; i++) {
        var equals = cookies[i].indexOf("=");
        var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
  },
  onRevokeChoice: function() {
    var type = this.options.type;
    if (type == 'opt-in') {
      // disable cookies
      var cookies = document.cookie.split(";");
      for(var i=0; i < cookies.length; i++) {
        var equals = cookies[i].indexOf("=");
        var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
    if (type == 'opt-out') {
      // enable cookies
    }
  }
});

// Lightbox
$('.gallery-item').on('click', function() {

  var product = $(this).closest('.js--product-model');

  // Product preview image click (GTM Event)
  dataLayer.push({
    'event': 'productClick',
    'ecommerce': {
      'click': {
        'actionField': {
          'list': $('.catalog-title h1').text().trim()
        },
        'products': [{
          'id': product.find('.js--product-sku').text().trim(),
          'name': product.find('.js--product-title').text().trim().replace(/([\[\]\{\}\"\„\“\,,])+/g, ''),
          'price': product.find('.btn-add-item-to-cart').data('price'),
          'brand': 'Mažoji Ragainė',
          'category': product.find('.js--product-first-level-category').text().trim(),
          'position': product.find('.btn-add-item-to-cart').data('position')
        }]
      }
    }
  });

  // Product preview image detail (GTM Event)
  dataLayer.push({
    'event': 'productDetail',
    'ecommerce': {
      'detail': {
        'actionField': {
          'list': $('.catalog-title h1').text().trim()
        },
        'products': [{
          'id': product.find('.js--product-sku').text().trim(),
          'name': product.find('.js--product-title').text().trim().replace(/([\[\]\{\}\"\„\“\,,])+/g, ''),
          'price': product.find('.btn-add-item-to-cart').data('price'),
          'brand': 'Mažoji Ragainė',
          'category': product.find('.js--product-first-level-category').text().trim(),
          'position': product.find('.btn-add-item-to-cart').data('position')
        }]
      }
    }
  });

});

const lightbox = glightbox({
	selector: '.gallery-item',
	touchNavigation: true,
	loop: true,
	autoplayVideos: true,
	onOpen: () => {
		// console.log('Lightbox opened')
	},
	beforeSlideLoad: (slide, data) => {
		// Need to execute a script in the slide?
		// You can do that here...
	}
});

// On $(document).ready execute scripts
$(document).ready(function(){

  // Prevent enter from keyboard
  $('.js--item-qty-input').keypress(function (e) {
    e.preventDefault();
  });

	// Recalculate qty on change input qty
	$('.js--item-qty-input').on('change', function() {

	    var field = $(this);
	    var price = $(field).closest('.row').find('.js--item-price').data('price');
	    var qty   = $(field).val();
	    var sum   = $(field).closest('.row').find('.js--item-sum');
	    var total_sum = price * qty;

	    $(sum).html('€&nbsp;' + parseFloat(total_sum).toFixed(2));

	});

  // Form sumbit
  $('form').submit(function(e) {
    $(this).find('button[type="submit"]').html(icon_loading);
    $(this).find('button[type="submit"]').attr('disabled', true);
  });

	$('.order-submit-button, .js--update-cart').on('click', function(e) {

    var selected_value = $('.select-taste-control');
		if (selected_value.val() == "") {
                //If the "Please Select" option is selected display error.
                // alert("Please select an product taste option!");
								$('.error_product_taste').html('');
								$('.error_product_taste').addClass('is-invalid').append('<span class="invalid-feedback js-invalid-feedback" role="alert"><strong>Prašome pasirinkti skonį</strong></span>');
								$("html, body").animate({ scrollTop: 0 }, "slow");
                return false;
    }

    return true;

	});

  // On allergens button click
  $('.js--ajax-load-allergens').on('click', function(e) {

    e.preventDefault();
    var productId = $(this).data('product-id');

    $.ajax({
      type: 'GET',
      url: '/ajax/product/get-allergens/' + productId + '/',
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      data: $('.js--cart-form').serialize(),
      success: function (response) {
        var allergens = response.productAllergens;

        // Load data
        $('.js--allergens-table').html('');
        for (var i = 0; i < allergens.length; i++) {
          $('.js--allergens-table').append('<tr><td>' + allergens[i].name + '</td></tr>');
        }
      },
      error: function( response ) {
        toastr['error'](data, 'Klaida!', {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          timeOut: 5000,
          extendedTimeOut: 0,
          positionClass: "toast-bottom-right"
        });
      }
    });

  });

  // On compositions button click
  $('.js--ajax-load-composition').on('click', function(e) {

    e.preventDefault();
    var productId = $(this).data('product-id');

    $.ajax({
      type: 'GET',
      url: '/ajax/product/get-composition/' + productId + '/',
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      data: $('.js--cart-form').serialize(),
      success: function (response) {

        var product = response.product;
        var composition_relation = '';

        // Load data
        $('.js--product-composition-content').html('');
        if (product.product_composition != null) {
          var composition_relation = '<h4>' + product.product_composition.name + '</h4><p>' + product.product_composition.description + '</p>';
        }

        // Append to HTML
        $('.js--product-composition-content').append(composition_relation + '<p>' + product.composition + '</p>');

      },
      error: function( response ) {
        toastr['error'](data, 'Klaida!', {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          timeOut: 5000,
          extendedTimeOut: 0,
          positionClass: "toast-bottom-right"
        });
      }
    });

  });

  // On bulk load button click
  $('.js--ajax-load-bulk-data').on('click', function(e) {

    e.preventDefault();
    var bulkId = $(this).data('bulk-id');

    $.ajax({
      type: 'GET',
      url: '/ajax/bulk/get-data/' + bulkId + '/',
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      data: $('.js--cart-form').serialize(),
      beforeSend: function (response) {
        $('.js--bulk-modal').css('opacity', .75);
         $('.js--bulk-modal-title').text('');
         $('.js--bulk-items-container').append(icon_loading);
         $('.js--bulk-gallery-container').append(icon_loading);
      },
      success: function (response) {

        var data = response.data;

        // Load data
        $('.js--bulk-modal').css('opacity', 1);
        $('.js--bulk-modal-title').text(data.title);

        // Load items with relations
        var items = data.get_items;
        var cover = data.image;
        var gallery = data.get_media;

        $('.js--bulk-items-container').html('');

        for (var i = 0; i < items.length; i++) {

          if (items[i].product_type === 'divider') {
            $('.js--bulk-items-container').append('<div class="bulk-item-divider"><span class="title">' + items[i].notes + '</span></div>');

          } else {

            var description = '';
            if (items[i].notes != null) {
              description = '<span class="bulk-item-description">' + items[i].notes + '</span>';
            }

            var taste = '';
            if (items[i].get_taste != null) {
              taste = '<span class="bulk-item-taste">' + items[i].get_taste.name + '</span>';
            }

            if (items[i].get_product != null) {
              $('.js--bulk-items-container').append('<div class="d-flex justify-content-between bulk-item"><div class="bulk-item-name"><span>' + icon_chevron + '</i>' + items[i].get_product.title + '</span>' + taste + '' + description + '</div><div class="bulk-item-count">' + items[i].qty + ' ' + items[i].unit + '</div></div>');
            }

          }
        }

        // Load galleries
        $('.js--bulk-gallery-container').html('');

        // Append the cover photo
        var coverFile = JSON.parse(cover)['medium'];
        if (coverFile != null) {
          $('.js--bulk-gallery-container').append('<div class="bulk-gallery-image mb-2"><img class="img-fluid" src="' + coverFile + '" alt=""></div>');
        }

        // Append gallery ites
        for (var i = 0; i < gallery.length; i++) {

          if (gallery[i].file != null) {
            var file = JSON.parse(gallery[i].file)['medium'];
          }

          $('.js--bulk-gallery-container').append('<div class="bulk-gallery-image mb-2"><img class="img-fluid" src="' + file + '" alt=""></div>');

        }

        // Button ATC data
        $('.js--btn-bulk-item-add-to-cart-modal').attr('data-id',    data.id);
        $('.js--btn-bulk-item-add-to-cart-modal').attr('data-price', data.price);
        $('.js--btn-bulk-item-add-to-cart-modal').attr('data-qty',   data.min_qty);

      },
      error: function( response ) {
        toastr['error'](data, 'Klaida!', {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          timeOut: 5000,
          extendedTimeOut: 0,
          positionClass: "toast-bottom-right"
        });
      }
    });

  });

  // On change select or input
  $('.js--cart-form input, .js--cart-form select').on('change', function(e) {

    e.preventDefault();

    var input = $(this);
    var cart = $('.shopping-cart');

    input.css('opacity', .5);
    input.prop('readonly', true);

    $.ajax({
			type: 'PATCH',
			url: '/ajax/cart/update-cart-items/',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			data: $('.js--cart-form').serialize(),
			success: function (response) {

        // Container msg
        toastr['success'](response.session, 'Ačiū!', {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          timeOut: 5000,
          extendedTimeOut: 0,
          positionClass: "toast-bottom-right"
        });

        input.css('opacity', 1);
        input.prop('readonly', false);

        var cartItems = response.cart;
        var weights = 0;
        var counts = 0;
        var prices = 0;
        var delivery_price = $('.js--delivery-price-value').val();

        var cartItemsArray = $.map(cartItems, function(value, index) {
          return [value];
        });

        // Sum up all weights
        for (var i = 0; i < cartItemsArray.length; i++) {
          weights += cartItemsArray[i].options.weight * cartItemsArray[i].qty;
          counts += parseFloat(cartItemsArray[i].qty);
          prices += parseFloat(cartItemsArray[i].subtotal);
        }

        // Cart header calculations
        cart.find('.badge').text(parseFloat(counts).toFixed(2));
        cart.attr('data-original-title', '€ ' + parseFloat(prices).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
        $('.js--total-weight').text( parseFloat(weights).toFixed(2) );
        $('.js--total-sum').text( '€ ' + parseFloat(prices).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") );
        $('.js--total-sum').text( '€ ' + parseFloat(prices).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") );
        $('#order_price').val( parseFloat(prices).toFixed(2) );
        $('.js--in-total').text( 'Iš viso: € ' + parseFloat( parseFloat(delivery_price) + parseFloat($('#order_price').val())).toFixed(2) );

        // Override row ids in hidden field and remove button data attributes
        for (var i = 0; i < cartItemsArray.length; i++) {
          $('#rowId_' + i).val(cartItemsArray[i].rowId);
          $('#removeCartRowId_' + i).attr('data-row-id', cartItemsArray[i].rowId);
          $('#removeCartRowId_' + i).attr('data-price', cartItemsArray[i].price);
          $('#removeCartRowId_' + i).attr('data-qty', cartItemsArray[i].qty);
          $('#removeCartRowId_' + i).attr('data-weight', cartItemsArray[i].options.total_weight);
        }

			},
			error: function( data ) {
        toastr['error']('Pakeistas skonis. Prašome perkrauti puslapį kad išsaugoti dar karta.', 'Klaida!', {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          timeOut: 5000,
          extendedTimeOut: 0,
          positionClass: "toast-bottom-right"
        });
			}
		});
  });

  // On click post order
	$('.order-submit-button').on('click', function(e) {

    // Check selected delivery type
    var delivery_type_value = $('.js--delivery-option.active input').val();
    if (typeof delivery_type_value === "undefined") {
      $('.js--delivery-type').find('.js--invalid-feedback-delivery-type').removeClass('d-none');
      return false;
    }

    // Check the date
    var delivery_date_value = $('.js--delivery-date-value').val();
    console.log(delivery_date_value);
    if (delivery_date_value === '') {
      $('.js--invalid-feedback-delivery-date').removeClass('d-none');
      return false;
    }

    // Сheck the timeslot
    var delivery_time_value = $('.js--timeslot-value').val();
    if (delivery_time_value === '') {
      $('.js--invalid-feedback-delivery-time').removeClass('d-none');
      return false;
    }

	});

	// Tooltips
	$('[data-toggle="tooltip"]').tooltip();

	// Dropdown
	$('.dropdown-toggle').dropdown();

	// Datetimepicker
  $('.datetimepicker').flatpickr({
			minDate: new Date().fp_incr(1), // 1 days from now
			plugins: [new confirmDatePlugin({})],
			dateFormat: 'Y-m-d H:i',
			enableTime: true,
			time_24hr: true,
			minuteIncrement: 15,
			locale: Lithuanian,
			minTime: '07:00',
			maxTime: '22:00',
			disableMobile: true,
      onChange: function(selectedDates, dateStr, instance) {

        var weekday = new Date(selectedDates).getDay();
        var hour = new Date(selectedDates).getHours();

        $('.order-bh-notice').addClass('d-none');

        // Check if it's Friday and after 4pm
        if (weekday == 5 && hour >= 16) {
          $('.order-bh-notice').removeClass('d-none');
        }
        // Check if it's Saturday or Sunday
        if (weekday == 6 || weekday == 0) {
          $('.order-bh-notice').removeClass('d-none');
        }
        // Check if it's Monday and before 12pm
        if (weekday == 1 && hour <= 12) {
          $('.order-bh-notice').removeClass('d-none');
        }

      }
	});

  // Cart V2
  var deliveryDatepickerV2 = $('.datetimepicker-v2').flatpickr({
      minDate: new Date().fp_incr(1), // 1 days from now
      dateFormat: 'Y-m-d',
      locale: Lithuanian,
      disableMobile: true,
      inline: true,
      onChange: function(selectedDates, dateStr, instance) {

        var delivery_type = $('.js--delivery-option.active').find('input').val();
        var branch_code   = $('.js--delivery-code-value').val();
        var date = new Date(selectedDates).getFullYear() + '-' + (new Date(selectedDates).getMonth() + 1) + '-' + new Date(selectedDates).getDate();

        // TEMP Date-specific conditions
        if (date == '2022-12-23' || date == '2022-12-24') {
          $('#branchCUP').prop('disabled', true);
          $('#branchPAN').prop('disabled', true);
          $('#branchCUP').parent().css('pointer-events', 'none');
          $('#branchPAN').parent().css('pointer-events', 'none');
          $('#branchCUP').parent().css('opacity', 0.5);
          $('#branchPAN').parent().css('opacity', 0.5);
          $('#branchNAU').click();
        } else {
          $('#branchCUP').prop('disabled', false);
          $('#branchPAN').prop('disabled', false);
          $('#branchCUP').parent().css('pointer-events', '');
          $('#branchPAN').parent().css('pointer-events', '');
          $('#branchCUP').parent().css('opacity', '');
          $('#branchPAN').parent().css('opacity', '');
        }

        // TEMP Hotfix
        if (date == '2022-12-19' ||
            date == '2022-12-20' ||
            date == '2022-12-21' ||
            date == '2022-12-22' ||
            date == '2022-12-23' ||
            date == '2022-12-24' ||
            date == '2022-12-19') {
              $('.order-submit-button').prop('disabled', true);
              $('.order-submit-button').text('Užsakymai nepriimami');
            } else {
              $('.order-submit-button').prop('disabled', false);
              $('.order-submit-button').text('Patvirtinti Užsakymą');
            }

        // Check delivery type selected
        if (typeof delivery_type === "undefined") {
          $('.js--invalid-feedback-delivery-type').removeClass('d-none');
          return false;
        }

        // Check branch selected
        if (branch_code === '') {
          $('.js--invalid-feedback-delivery-branch').removeClass('d-none');
          return false;
        }

        // Load days orders
        fetchOrdersForDate(dateStr, delivery_type, branch_code);

        // Notices
        var weekday = new Date(selectedDates).getDay();
        var hour = new Date(selectedDates).getHours();

        // Hide errors
        $('.order-bh-notice').addClass('d-none');
        $('.js--invalid-feedback-delivery-date').addClass('d-none');

        // Check if it's Friday and after 4pm
        if (weekday == 5 && hour >= 16) {
          $('.order-bh-notice').removeClass('d-none');
        }
        // Check if it's Saturday or Sunday
        if (weekday == 6 || weekday == 0) {
          $('.order-bh-notice').removeClass('d-none');
        }
        // Check if it's Monday and before 12pm
        // if (weekday == 1 && hour <= 12) {
        //   $('.order-bh-notice').removeClass('d-none');
        // }

      }
  });

  // Delivery boxes
  $(document).on('click', '.js--delivery-option', function(e) {

    var delivery_option = $(this).find('input').attr('data-delivery-type');
    var delivery_price  = $(this).find('input').attr('data-delivery-price');

    // Show the box
    $('.js--delivery-options-box').addClass('d-none');
    $('.js--delivery-options-box--' + delivery_option).removeClass('d-none');
    $('.js--delivery-type').find('.js--invalid-feedback-delivery-type').addClass('d-none');
    $('.js--timeslots').addClass('d-none');

    // Delivery price
    $('.cart-summary h4').find('.js--delivery').remove();
    $('.cart-summary h4').find('.js--in-total').remove();
    $('.cart-summary h4').append( '<p class="js--delivery mt-3">Prisatymas: € ' + parseFloat(delivery_price).toFixed(2) + '</p>' );
    $('.cart-summary h4').append( '<p class="js--in-total">Iš viso: € ' + parseFloat( parseFloat(delivery_price) + parseFloat($('#order_price').val()) ).toFixed(2)  + '</p>' );

    if (delivery_option == 'address') {
      var delivery_code = $(this).find('input').attr('data-branch-code');
      $('.js--delivery-options-box--address textarea').attr('required', 'required');
      $('.js--delivery-options-box--branch .form-check label').removeClass('filial-button');
      $('.js--delivery-options-box--branch .form-check input').prop('checked', false);
      $('.js--delivery-options-box--branch .form-check input').removeAttr('required');
      if ($('.js--delivery-date-value').val() != '') {
        fetchOrdersForDate($('.js--delivery-date-value').val(), delivery_option, delivery_code);
      }

    } else {
      var delivery_code = $('.js--delivery-options-box--branch .form-check .filial-button').attr('data-branch-code');
      $('.js--delivery-options-box--address textarea').removeAttr('required');
      $('.js--delivery-options-box--branch .form-check input').attr('required', 'required');
      $('.js--delivery-options-box--address textarea').val('');

    }

    // Set delivery code, price etc.
    $('.js--delivery-code-value').val( delivery_code );
    $('.js--delivery-price-value').val( delivery_price );

  });

  // Select the branch
  $(document).on('click', '.js--delivery-options-box--branch label', function(e) {

    $('.js--delivery-options-box--branch .form-check label').removeClass('filial-button');
    $(this).addClass('filial-button');
    $(this).find('input').attr('checked', 'checked');
    $('.js--delivery-type').find('.js--invalid-feedback-delivery-branch').addClass('d-none');

    // Disable weekends for closed branches
    var weekends = $(this).data('weekend-days');
    deliveryDatepickerV2.set('disable', [
        function(date) {
            if (weekends == 'Sat' ) {
              return (date.getDay() === 6);
            }
            if (weekends == 'Sun' ) {
              return (date.getDay() === 0);
            }
            if (weekends == 'Sat, Sun' || weekends == 'Sat,Sun' ) {
              return (date.getDay() === 6 || date.getDay() === 0);
            }
        }
    ]);

    // Set branch code
    var delivery_code = $(this).attr('data-branch-code');
    $('.js--delivery-code-value').val( delivery_code );
    if ($('.js--delivery-date-value').val() != '') {
      fetchOrdersForDate($('.js--delivery-date-value').val(), 'branch', delivery_code);
    }

  });

  // Timeslots values
  $(document).on('click', '.js--timeslot-option', function(e) {

    var timeslot_value = $(this).find('input').val();
    $('.js--timeslot-value').val(timeslot_value);
    $('.js--invalid-feedback-delivery-time').addClass('d-none');

  });

  // Payment boxes
  $(document).on('click', '.js--payment-option', function(e) {

    var show_prepayment_field = $(this).find('input').attr('data-show-prepayment-field');
    if (show_prepayment_field === 'yes') {
      $('.js--payment-options-box-prepayment').removeClass('d-none');
    } else {
      $('.js--payment-options-box-prepayment').addClass('d-none');
    }

  });

  // Get all orders for date
  function fetchOrdersForDate(date, delivery_type, branch_code) {
    $.ajax({
      type: 'POST',
      url:  '/ajax/orders-for-date',
      data: {
        date:           date,
        weekday_number: new Date(date).getDay(),
        delivery_type:  delivery_type,
        branch_code:    branch_code
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      beforeSend: function (response) {
        $('.js--ajax-timeslots').html('<div class="fa-3x text-warning">' + icon_loading + '</div>');
        $('.js--timeslot-value').val('');
        $('.js--invalid-feedback-delivery-time').addClass('d-none');
      },
      success: function (response) {

        var timeslots = response.timeslots, key;

        // Load data
        $('.js--timeslots').removeClass('d-none');
        $('.js--ajax-timeslots').html('');

        for (key in timeslots) {
          if (key != '00:00') {
            $('.js--ajax-timeslots').append('<label class="btn btn-primary js--timeslot-option"><input type="radio" value="' + key + '" autocomplete="off" class="form-check">' + key + '</label>');
          } else {
            $('.js--ajax-timeslots').html('<div class="order-no-time-notice mt-3">' + icon_warning + 'Atsiprašome, bet pasirinktai datai laiko nėra. Pasirinkite kitą datą, kad gautumėte užsakymą.</div>');
          }
        }

      },
      error: function( response ) {
        $('.js--timeslots').removeClass('d-none');
        $('.js--ajax-timeslots').html('<div class="fa-3x text-danger">' + icon_warning + '</div>');
      }

    });
  }
  // END Cart V2

	// Index OwlCarousel Slider Above Footer
	$('.home-gallery').owlCarousel({
		loop: true,
		responsiveClass: true,
		navText: [" ", " "],
		responsive:{
			0:{
					items: 1,
					nav: false,
					margin: 10,
					nav: true
			},
			400: {
					items: 2,
					nav: false,
					margin: 10,
					nav: true
			},
			600:{
					items: 3,
					nav: false,
					margin: 10,
					nav: true
			},
			1000:{
					items: 4,
					nav: false,
					loop: true,
					margin: 10,
					nav: true
			},
			2000:{
					items: 5,
					nav: false,
					loop: true,
					margin: 10,
					nav: true
			}
		}
	});

	// About Us Page OwlCarousel Slider Above Footer
	$('.about-gallery').owlCarousel({
    loop: true,
    responsiveClass: true,
		navText: [" ", " "],
    responsive:{
      0:{
          items: 1,
          nav: true,
          margin: 0
      },
      600:{
          items: 2,
          nav: true,
          margin: 0
      },
      1000:{
          items: 4,
          nav: true,
          margin: 0
      },
			1400:{
          items: 4,
          nav: true,
          margin: 0
      }

    }
  });

	// About Us Page OwlCarousel Slider Reviews
	$('.about-reviews').owlCarousel({
    loop: true,
    responsiveClass: true,
		navText: [" ", " "],
    responsive:{
      0:{
          items: 1,
          nav: true,
          margin: 20
      },
      600:{
          items: 2,
          nav: true,
          margin: 20
      },
      1000:{
          items: 3,
          nav: true,
          margin: 20
      }
    }
  });

  // Similar products slider
	$('.similar-products-owl').owlCarousel({
    loop: true,
    responsiveClass: true,
		navText: [" ", " "],
    responsive:{
      0:{
          items: 1,
          nav: true,
          margin: 20
      },
      600:{
          items: 2,
          nav: true,
          margin: 20
      },
      1000:{
          items: 4,
          nav: true,
          margin: 20
      }
    }
  });

	// Ajax for adding product to cart
	$(document).on('click', '.btn-add-item-to-cart', function(e){
		e.preventDefault();
		var button = $( this );
    var all_buttons = $('.btn-add-item-to-cart[data-id=' + button.data('id') + ']');
		var cart = $('.shopping-cart');
		var items_count = parseFloat(cart.find('.badge').text());
		var total_count = parseFloat(cart.data('original-title').substring(2).replace(/,/g, ''));
		var selected_value = $('.select-form-control').val();
		var p_id = $(this).data('id');
		var p_qty = $(this).data('qty');
		var p_price = $(this).data('price');

		$.ajax({
			type: 'POST',
			// Chech with https, if there cause an error without trailing slash
			url: '/ajax/cart/add-item',
			dataType: 'json',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			data: {
				id: p_id,
				qty: p_qty,
				selected: selected_value
			},
			success: function( data, msg ) {

				// Container msg
        toastr['success'](data.session, 'Ačiū!', {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          timeOut: 5000,
          extendedTimeOut: 0,
          positionClass: "toast-bottom-right"
        });

				// Button class
				button.find('svg').html(icon_success);

        // Set to all buttons checkmark
        for (var i = 0; i < all_buttons.length; i++) {
          $(all_buttons[i]).find('svg').html(icon_success);
        }

				// Cart header calculations
				var total_qty = items_count + parseFloat(p_qty);
				var total_price = total_count + parseFloat(p_price * p_qty);
				cart.find('.badge').text(parseFloat(total_qty).toFixed(2));
				cart.attr('data-original-title', '€ ' + parseFloat(total_price).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));

        // Product add to cart (GTM Event)
        dataLayer.push({
          'event': 'addToCart',
          'ecommerce': {
            'add': {
              'products': [{
                'id': data.product.sku,
                'name': data.product.title.replace(/([\[\]\{\}\"\„\“\,,])+/g, ''),
                'price': data.price,
                'brand': 'Mažoji Ragainė',
                'category': (data.category != null) ? data.category.name : null,
                'quantity': data.qty
              }]
            }
          }
        });

			},
			error: function( data, msg ) {

				button.find('svg').html(icon_warning);
        button.css('color', '#f44336');

        // Set to all buttons error
        for (var i = 0; i < all_buttons.length; i++) {
				  $(all_buttons[i]).find('svg').html(icon_warning);
          $(all_buttons[i]).css('color', '#f44336');
        }

			}
		});
	});

  // Ajax for adding bulk to cart
	$(document).on('click', '.btn-add-bulk-to-cart', function(e){
		e.preventDefault();
		var button = $( this );
    var all_buttons = $('.btn-add-bulk-to-cart[data-id=' + button.data('id') + ']');
		var cart = $('.shopping-cart');
		var items_count = parseFloat(cart.find('.badge').text());
		var total_count = parseFloat(cart.data('original-title').substring(2).replace(/,/g, ''));
		var selected_value = $('.select-form-control').val();
		var p_id = $(this).data('id');
		var p_qty = $(this).data('qty');
		var p_price = $(this).data('price');

		$.ajax({
			type: 'POST',
			// Chech with https, if there cause an error without trailing slash
			url: '/ajax/cart/add-bulk',
			dataType: 'json',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			data: {
				id: p_id,
				qty: p_qty,
				selected: selected_value
			},
			success: function( data, msg ) {

				// Container msg
        toastr['success'](data.session, 'Ačiū!', {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          timeOut: 5000,
          extendedTimeOut: 0,
          positionClass: "toast-bottom-right"
        });

				// Button class
				button.find('svg').html(icon_success);

        // Set to all buttons checkmark
        for (var i = 0; i < all_buttons.length; i++) {
  				$(all_buttons[i]).find('svg').html(icon_success);
        }

				// Cart header calculations
				var total_qty = items_count + parseFloat(p_qty);
				var total_price = total_count + parseFloat(p_price * p_qty);
				cart.find('.badge').text(parseFloat(total_qty).toFixed(2));
				cart.attr('data-original-title', '€ ' + parseFloat(total_price).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));

        // Product add to cart (GTM Event)
        dataLayer.push({
          'event': 'addToCart',
          'ecommerce': {
            'add': {
              'products': [{
                'id': data.product.sku,
                'name': data.product.title.replace(/([\[\]\{\}\"\„\“\,,])+/g, ''),
                'price': data.price,
                'brand': 'Mažoji Ragainė',
                'category': (data.category != null) ? data.category.name : null,
                'quantity': data.qty
              }]
            }
          }
        });

			},
			error: function( data, msg ) {

				button.find('svg').html(icon_warning);
        button.css('color', '#f44336');

        // Set to all buttons error
        for (var i = 0; i < all_buttons.length; i++) {
  				$(all_buttons[i]).find('svg').html(icon_warning);
          $(all_buttons[i]).css('color', '#f44336');
        }

			}
		});
	});

	// AJAX: remove item from cart
	$('.js--remove-cart-row').on('click', function(){

		var cart = $('.shopping-cart');
		var items_count = parseFloat(cart.find('.badge').text());
		var total_count = parseFloat(cart.data('original-title').substring(2).replace(/,/g, ''));
		var button = $(this);
		var rowId = button.data('row-id');
		var weight = button.data('weight');
		var p_price = button.data('price');
		var p_qty = button.data('qty');
		var total_weight = parseFloat($('.js--total-weight').text());

		$.ajax({
			type: 'POST',
			url: '/ajax/cart/remove-item/' + rowId + '/',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			success: function(data) {

				// Container msg
        toastr['success'](data.session, 'Ačiū!', {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          timeOut: 5000,
          extendedTimeOut: 0,
          positionClass: "toast-bottom-right"
        });

				// Cart header calculations
				var total_qty = items_count - parseFloat(p_qty);
				var total_price = total_count - parseFloat(p_price * p_qty);
				cart.find('.badge').text(parseFloat(total_qty).toFixed(2));
				cart.attr('data-original-title', '€ ' + parseFloat(total_price).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
				$('.js--total-weight').text( parseFloat(total_weight - weight).toFixed(2) );
				$('.js--total-sum').text( '€ ' + parseFloat(total_price).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") );

				// Remove row
				button.parent().parent().slideUp();
				button.parent().parent().remove();

        // Product remove from cart (GTM Event)
        dataLayer.push({
          'event': 'removeFromCart',
          'ecommerce': {
            'remove': {
              'products': [{
                'id': data.cartItem.options.sku,
                'name': data.cartItem.name.replace(/([\[\]\{\}\"\„\“\,,])+/g, ''),
                'price': data.cartItem.price,
                'brand': 'Mažoji Ragainė',
                'category': (data.cartItem.options.first_level_category != null) ? data.cartItem.options.first_level_category : null,
                'quantity': data.cartItem.qty
              }]
            }
          }
        });

				// Refresh page if 0 items
				if ($('.cart-content').length == 0) {
					location.reload();
				}

			},
			error: function( data, msg ) {
				button.find('.fa').removeClass('fa-times');
				button.find('.fa').addClass('fa-exclamation-triangle');
				button.css('color', '#f44336');
			}
		});
	});

  // On click detailed description
  $('.js--show-full-description').on('click', function() {
    $(this).parent().find('.js--catalog-description-fixed-content-height').removeClass('catalog-description-fixed-content-height');
    $(this).remove();
  });

  // Step up
  $('.js--step-up').on('click', function(e) {
    var input = $(this).closest('.cart-item-qty').find('input[type=number]');
    var maxVal = parseFloat($(input).attr('max'));
    var newVal = parseFloat( parseFloat(input.val()) + parseFloat($(input).attr('step')) ).toFixed(2);
    if ( newVal <= maxVal ) {
      input.val( newVal );
      input.change();
    }
  });

  // Step down
  $('.js--step-down').on('click', function(e) {
    var input  = $(this).closest('.cart-item-qty').find('input[type=number]');
    var minVal = parseFloat($(input).attr('min'));
    var newVal = parseFloat( parseFloat(input.val()) - parseFloat($(input).attr('step')) ).toFixed(2);
    if ( newVal >= minVal ) {
      input.val( newVal );
      input.change();
    }
  });

	// Burger Menu
	$('.navbar-toggler').click(function(){
		$(this).toggleClass('open');
	});

	$('.collapse')
        .on('shown.bs.collapse', function(event) {
          event.stopPropagation();
            $(this)
                .parent()
                .find(".fa-chevron-right")
                .removeClass("fa-chevron-right")
                .addClass("fa-chevron-down");
        }).on('hidden.bs.collapse', function(event) {
         event.stopPropagation();
            $(this)
                .parent()
                .find(".fa-chevron-down")
                .removeClass("fa-chevron-down")
                .addClass("fa-chevron-right");
  });
// Shrinkable Navigation

  $(window).scroll(function () {
    var sc = $(window).scrollTop()
    if (sc > 85) {
      $(".navbar").addClass("nav-small");
      $('#header_placeholder').css({display: 'block'});
    } else {
      $(".navbar").removeClass("nav-small");
      $('#header_placeholder').css({display: 'none'});
    }
  });

	$(".navbar-toggler").click(function(){
		$(".cart-ico").fadeToggle(100);
	});

  // Hide Filters
	$('.filter').click(function(){
		$('.conditery-content article:nth-child(2) *:not(.filter), .culinary-content article:nth-child(2) *:not(.filter)').slideToggle('fast');
	});

  // Approx Cake Weight
  $("[id*='makeacake-']").mouseover(function() {
    $(this).keyup(function() {

      var q = $(this).find("[name='quantity']").val(),
          w = $(this).find("[name='weight']").val();

        if (q) {
         $(this).find("[name='weight']").attr("placeholder", ( q * 0.1 ));
        } else if (w) {
         $(this).find("[name='quantity']").attr("placeholder", ( w / 0.1 ));
        } else {
         console.log('Approx Cake Weight Error');
        }
    });
  });

});

// Dropzone
if ($('div.js--upload-order-files').length > 0) {
let dropzone = new Dropzone(
  'div.js--upload-order-files',
  {
    url:              '/ajax/cart/upload-dropzone-files',
    method:           'post',
    headers:          {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
    uploadMultiple:   true,
    maxFilesize:      2,
    acceptedFiles:    'image/*',
    autoProcessQueue: true,
    addRemoveLinks:   false,
  }
);
dropzone.on('addedfile', file => {
  console.log(file);
  console.log(`File added: ${file.name}`);
});
}

// Filter slider
if ($('#priceSlider').length > 0) {
  var priceSlider = document.getElementById('priceSlider');
  noUiSlider.create(priceSlider, {
    start: [10, 40],
    connect: true,
    range: {
        'min': 1,
        'max': 150
    },
    tooltips: true,
  });

  var inputFormatLower = document.querySelector('.js--input-format-lower');
  var inputFormatUpper = document.querySelector('.js--input-format-upper');
  
  priceSlider.noUiSlider.set([inputFormatLower.value, inputFormatUpper.value]);
  priceSlider.noUiSlider.on('update', function (values, handle) {
      inputFormatLower.value = values[0];
      inputFormatUpper.value = values[1];
  });

}

// NoUiSlider cakes weight
if ($('#cakeWeight1').length > 0) {
	var softCakeWeightSlider1 = document.getElementById('cakeWeight1');
	noUiSlider.create(softCakeWeightSlider1, {
		start: 3,
		range: {
			min: 0,
			max: 12
		},
		pips: {
			mode: 'values',
			values: [2, 10],
			density: 2
		}
	});
}

if ($('#cakeWeight2').length > 0) {
	var softCakeWeightSlider2 = document.getElementById('cakeWeight2');
	noUiSlider.create(softCakeWeightSlider2, {
		start: 3,
		range: {
			min: 0,
			max: 12
		},
		pips: {
			mode: 'values',
			values: [2, 10],
			density: 2
		}
	});
}

if ($('#cakeWeight3').length > 0) {
	var softCakeWeightSlider3 = document.getElementById('cakeWeight3');
	noUiSlider.create(softCakeWeightSlider3, {
		start: 3,
		range: {
			min: 0,
			max: 12
		},
		pips: {
			mode: 'values',
			values: [2, 10],
			density: 2
		}
	});
}

if ($('#cakeWeight4').length > 0) {
	var softCakeWeightSlider4 = document.getElementById('cakeWeight4');
	noUiSlider.create(softCakeWeightSlider4, {
		start: 3,
		range: {
			min: 0,
			max: 12
		},
		pips: {
			mode: 'values',
			values: [2, 10],
			density: 2
		}
	});
}

if ($('#cakeWeight5').length > 0) {
	var softCakeWeightSlider5 = document.getElementById('cakeWeight5');
	noUiSlider.create(softCakeWeightSlider5, {
		start: 3,
		range: {
			min: 0,
			max: 12
		},
		pips: {
			mode: 'values',
			values: [2, 10],
			density: 2
		}
	});

}

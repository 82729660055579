import { getProductAttribute, getCherriesSettings } from './ajax.js';

function setProductPrice(price) {
    $('#ccProductPrice').val(price);
}

let prices = {};

function mediumPrice(array) {
    let sum = 0; 
    let count = Object.values(array).length;
    Object.values(array).forEach(item => {
      if (item == "" || item == undefined) {
        sum += 0;
      }
      sum += item;
    });
    
    console.log('array total', sum); 
    console.log('array count', count);
    return sum / count;
}
  
export function calculatePrice(baseprice, floor) {
    
    // Fetch the variables
    let extra_price     = 0;
    let cherries_count  = getProductAttribute('cherries');
    let cherries_prices = getCherriesSettings();

    // Calculate extra price
    for (let i = 1; i < cherries_count + 1; i++) {
        extra_price += parseFloat(cherries_prices[i]);
    }

    prices[floor] = baseprice;
    delete prices[undefined];
    
    console.log('prices array', prices);
    console.log('extra', extra_price);

    var price = mediumPrice(prices);
    console.log('medium price', price);

    setProductPrice(price + extra_price);

}

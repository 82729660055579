import { cakeConstructorReset, cakeConstructorResetRenderFloors } from './reset.js';
import { calculatePrice } from './price.js';
import { resetSteps, setActiveStep } from './steps.js';
import { getCakeBasePrice } from './ajax.js';

// STEP 1: Select the custom cake product
$('.js--create-custom-cake').on('click', function() {

    // Reset
    cakeConstructorReset();
    cakeConstructorResetRenderFloors();
    resetSteps();

    // Variables
    var pid = $(this).data('id');
    var price = $(this).data('price');
    var qty = $(this).data('qty');
    var weight = $(this).data('weight');
    var floors = $(this).data('floors');

    // Set values
    $('#ccProductId').val(pid);
    $('#ccProductPrice').val(price);
    $('#ccProductQty').val(qty);
    $('#ccProductWeight').val(weight);
    $('#ccProductFloors').val(floors);

    // Set max-floor for cakes
    $('.js--cake-total-height').html(floors);

    setActiveStep(1);
    calculatePrice( getCakeBasePrice() );
    
    console.log(pid);
});
// Create cake slider
export function createCakeWeightSlider(min, max) {

    var cake_type = $('#ccCakeType').val();

    if ($('#weightSlider__' + cake_type).length > 0) {
        
        var weightSlider = document.getElementById('weightSlider__' + cake_type);
        var startWeight = parseFloat((min + max) / 2).toFixed(2);
        
        // Remove existing sliders
        if (weightSlider.noUiSlider !== undefined) {
            weightSlider.noUiSlider.destroy();
        }

        // Create slider
        noUiSlider.create(weightSlider, { 
          start: [startWeight],
          range: {
              'min': [min],
              'max': [max]
          },
          tooltips: true,
        });

        var inputCakeWeight = document.querySelector('#ccCakeWeight');
        var spanCakeTotalWeights = document.querySelectorAll('.js--cake-total-weight');
        var floorWeights = document.querySelectorAll('.js--floor-taste-' + cake_type);
  
        // weightSlider.noUiSlider.set([inputCakeWeight.value]);
        weightSlider.noUiSlider.on('update', function (values, handle) {

            inputCakeWeight.value = values[0];
            spanCakeTotalWeights.forEach(async (spanCakeTotalWeight) => {
                spanCakeTotalWeight.innerHTML = values[0] + ' kg';
            });
            
            // Weights by floors 
            // TODO: formula, pricing for each floor
            floorWeights.forEach(async (floorWeight) => {
                floorWeight.dataset.floorWeight = values[0];
            });

        });

    }
}
import { calculatePrice } from './price.js';
require('./price.js');

// Show all tastes
if ($('.js--show-all-floor-tastes').length > 0) {
    $('.js--show-all-floor-tastes').on('click', function() {
        $(this).closest('.js--floor-taste').find('.btn').removeClass('d-none');
        $(this).addClass('d-none');
    });
}

// Select taste
$('.js--select-taste').on('click', function() {
    
    var color = $(this).css('border-color');
    var cake_floor = $(this).closest('.js--floor-taste').data('cake-floor');
    var price = $(this).data('taste-price');
    var composition = $(this).data('taste-composition');
    
    // Calculate the price depends on taste
    calculatePrice(price, cake_floor);

    // Set composition
    $('.js--cake-composition-content-floor-' + cake_floor + '-ajax').html('<strong>Aukštas ' + cake_floor + '</strong>: ' + composition).show();

    // Rounded cake
    $('.js--floor-render[data-cake-floor="' + cake_floor + '"] > svg > .top-side').css('fill', color).css('opacity', .85);
    $('.js--floor-render[data-cake-floor="' + cake_floor + '"] > svg > .left-side').css('fill', color);
    $('.js--floor-render[data-cake-floor="' + cake_floor + '"] > svg > .right-side').css('fill', color).css('opacity', .95);
    
    // Square cake 
    $('.js--floor-render[data-cake-floor="' + cake_floor + '"] > svg > .top-s-side').css('fill', color);
    $('.js--floor-render[data-cake-floor="' + cake_floor + '"] > svg > .left-s-side').css('fill', color).css('opacity', .75);
    $('.js--floor-render[data-cake-floor="' + cake_floor + '"] > svg > .right-s-side').css('fill', color).css('opacity', .85);

});

// Remove tooltips
$('.modal--cake-constructor').on( 'scroll', function() {
    $('[data-toggle="tooltip"]').tooltip('hide');
});
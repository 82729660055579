import { addFloor, removeFloor } from './floors.js';

// Add floor
if ($('.js--add-floor').length > 0) {
    $('.js--add-floor').on('click', function() {
        addFloor();
    });
}

// Remove floor
if ($('.js--remove-floor').length > 0) {
    $('.js--remove-floor').on('click', function() {
        removeFloor();
    });
}